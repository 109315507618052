namespace Portico.Insights {
    // Application Insights Module
    const module = angular.module('app');

    // setup some features that can only be done during the configure pass
    module.config([
        '$provide',
        '$httpProvider',
        ($provide: ng.auto.IProvideService, $httpProvider: ng.IHttpProvider) => {
            interceptLogging($provide);
            interceptExceptions($provide);
            interceptHttp($provide, $httpProvider);
        },
    ]);

    interface AiRecord {
        getAbsoluteUrl(): string;
        getPathName(): string;
        CalculateMetrics(): void;
        completed: boolean;
        requestHeadersSize: number | null;
        ttfb: any;
        responseReceivingDuration: number | null;
        callbackDuration: number | null;
        ajaxTotalDuration: number | null;
        aborted: any;
        pageUrl: any;
        requestUrl: any;
        requestSize: number;
        method: string;
        status: number;
        requestSentTime: number | null;
        responseStartedTime: number | null;
        responseFinishedTime: number | null;
        callbackFinishedTime: number | null;
        endTime: number | null;
        id: string;
    }

    enum SeverityLevel {
        Verbose = 0,
        Information = 1,
        Warning = 2,
        Error = 3,
        Critical = 4,
    }

    class Options {
        applicationName = 'Portico';
        autoStateChangeTracking = true;
        properties: { [key: string]: any } = {};
        tags: { [key: string]: any } = {};
    }

    // the run block sets up automatic page view tracking
    module.run([
        '$rootScope',
        '$state',
        '$location',
        '$timeout',
        'dataService',
        'storageService',
        'appInsights',
        'appInsightsSettings',
        (
            $rootScope: ng.IRootScopeService,
            $state: ng.ui.IStateService,
            $location: ng.ILocationService,
            $timeout: ng.ITimeoutService,
            dataService: Portico.Services.IDataService,
            storageService: Portico.Services.IStorageService,
            appInsights: appInsights,
            options: Options
        ) => {
            if (!appInsights.ai) {
                // ai is not loaded
                console.warn('Application Insights not loaded');
                return;
            }

            if (appInsights.options) {
                angular.extend(appInsights.options, options);
            }

            const queue: Array<() => void> = [];

            queue.push(() => {
                appInsights.ai.context.addTelemetryInitializer(envelope => {
                    try {
                        let item: {
                            properties: { [key: string]: string };
                            measurements: { [key: string]: number };
                        } =
                            envelope.data.baseData;
                        envelope.tags['ai.cloud.role'] = envelope.tags['ai.device.roleName'] = 'Portico UI';

                        item.properties = _.merge(item.properties, appInsights.options.properties);
                        item.properties['programId'] = dataService.getProgramId().toString();
                        item.properties['tenantId'] = dataService.getEnvironmentValue(
                            Portico.Models.KnownKeyNames.tenant
                        );
                        const sessionId = storageService.getItem<string>(
                            Models.StorageType.Session,
                            Portico.Models.SESSION_STORAGE_KEY
                        );
                        if (sessionId) {
                            item.properties[Portico.Models.SESSION_STORAGE_KEY] = sessionId;
                        }
                    } catch (e) {
                        // We're not ready yet, which is okay
                    }
                });

                appInsights.ai.context.addTelemetryInitializer(envelope => {
                    let item: {
                        properties: { [key: string]: string };
                        measurements: { [key: string]: number };
                    } =
                        envelope.data.baseData;
                    item.properties['state'] = $state.current.name;
                    _.each(
                        $state.params,
                        (value, key) => (item.properties[`param.${key}`] = value)
                    );
                });

                appInsights.ai.context.addTelemetryInitializer(envelope => {
                    let item: {
                        properties: { [key: string]: string };
                        measurements: { [key: string]: number };
                    } =
                        envelope.data.baseData;
                    item.properties['portalId'] = _.get(dataService, 'setup.portal.id', null);
                    envelope.tags['ai.cloud.roleInstance'] = envelope.tags['ai.device.roleInstance'] = item.properties['portalName'] = _.get(dataService, 'setup.portal.name', null);
                });

                appInsights.ai.trackPageView();

                // $timeout(() => appInsights.ai.startTrackPage(getPageName($state.current, $state.params)));
            });

            function getEventName(to: ng.ui.IState, toParams: object) {
                return `${appInsights.options.applicationName}:goto:${href(to, toParams)}`;
            }
            function getPageName(page: ng.ui.IState, pageParams: object) {
                return `${appInsights.options.applicationName}@${href(page, pageParams)}`;
            }
            function href(page: ng.ui.IState, pageParams: object) {
                return ($state.href(page, pageParams) || '').replace('#', '');
            }

            if (appInsights.options.autoStateChangeTracking) {
                $rootScope.$on(
                    '$stateChangeStart',
                    (
                        event: ng.IAngularEvent,
                        to: ng.ui.IState,
                        toParams: { [key: string]: any },
                        from: ng.ui.IState,
                        fromParams: { [key: string]: any }
                    ) => {
                        if (appInsights.ai.startTrackEvent) {
                            appInsights.ai.startTrackEvent(getEventName(to, toParams));
                        }
                    }
                );

                $rootScope.$on(
                    '$stateChangeError',
                    (
                        event: ng.IAngularEvent,
                        to: ng.ui.IState,
                        toParams: { [key: string]: any },
                        from: ng.ui.IState,
                        fromParams: { [key: string]: any }
                    ) => {
                        if (appInsights.ai.stopTrackEvent) {
                            appInsights.ai.stopTrackEvent(getEventName(to, toParams), {
                                toHref: href(to, toParams),
                                fromHref: href(from, fromParams),
                            });
                        }
                    }
                );

                $rootScope.$on(
                    '$stateChangeSuccess',
                    (
                        event: ng.IAngularEvent,
                        to: ng.ui.IState,
                        toParams: { [key: string]: any },
                        from: ng.ui.IState,
                        fromParams: { [key: string]: any }
                    ) => {
                        if (appInsights.ai.stopTrackEvent) {
                            appInsights.ai.stopTrackEvent(getEventName(to, toParams), {
                                toHref: href(to, toParams),
                                fromHref: href(from, fromParams),
                            });
                            appInsights.ai.stopTrackPage(
                                getPageName(from, fromParams),
                                href(from, fromParams),
                                {
                                    toHref: href(to, toParams),
                                    fromHref: href(from, fromParams),
                                }
                            );
                            appInsights.ai.startTrackPage(getPageName(to, toParams));
                        }
                    }
                );

                _.each(queue, q => {
                    if (appInsights.ai.queue) {
                        appInsights.ai.queue.push(...queue);
                    } else {
                        q();
                    }
                });
            }
        },
    ]);

    export interface appInsights {
        ai: any;
        options: Options;
    }

    module.provider('appInsights', [
        'serverConstants',
        serverConstants => new AppInsightsProvider(serverConstants),
    ]);

    class AppInsightsProvider implements angular.IServiceProvider {
        constructor(private serverConstants: Portico.Models.Interfaces.IServerConstants) {}
        // configuration properties for the provider
        private _options = new Options();

        configure(options: Options) {
            angular.extend(this._options, options);
        }
        $get = [
            () => {
                return {
                    options: this._options,
                    ai: (window as any).appInsights,
                };
            },
        ];
    }

    function delegateMethod(originalFn: ng.ILogCall, level: SeverityLevel): ng.ILogCall {
        var interceptingFn = function() {
            var args = [].slice.call(arguments);
            // track the call
            var message = args.join(' ');
            // Call the original
            originalFn.apply(null, args);
            if ((<any>window).appInsights) (<any>window).appInsights.trackTrace(message, {}, level);
        };

        angular.extend(interceptingFn, originalFn);
        return <ng.ILogCall>interceptingFn;
    }

    // $log interceptor .. will send log data to application insights, once app insights is
    // registered. $provide is only available in the config phase, so we need to setup
    // the decorator before app insights is instantiated.
    function interceptLogging($provide: ng.auto.IProvideService) {
        $provide.decorator('$log', [
            '$delegate',
            ($delegate: ng.ILogService) => {
                $delegate.debug = delegateMethod($delegate.debug, SeverityLevel.Verbose);
                $delegate.info = delegateMethod($delegate.info, SeverityLevel.Information);
                $delegate.warn = delegateMethod($delegate.warn, SeverityLevel.Warning);
                $delegate.error = delegateMethod($delegate.error, SeverityLevel.Error);
                $delegate.log = delegateMethod($delegate.log, SeverityLevel.Verbose);

                return $delegate;
            },
        ]);
    }

    function interceptExceptions($provide: ng.auto.IProvideService) {
        $provide.decorator('$exceptionHandler', [
            '$delegate',
            ($delegate: ng.IExceptionHandlerService) => {
                const origExceptionHandler = $delegate;
                return exception => {
                    // Call the original
                    origExceptionHandler(exception);
                    if ((<any>window).appInsights && (<any>window).AI)
                        (<any>window).appInsights.trackException(
                            exception,
                            undefined,
                            undefined,
                            undefined,
                            SeverityLevel.Error
                        );
                };
            },
        ]);
    }

    function interceptHttp($provide: ng.auto.IProvideService, $httpProvider: ng.IHttpProvider) {
        type ResponseType = ng.IHttpResponse<any> & { config: { aiData: AiRecord } };
        type RequestType = ng.IRequestConfig & { aiData: AiRecord };
        $provide.factory('aiTrackGateway', [
            'appInsights',
            'webServiceUrls',
            (
                appInsights: Portico.Insights.appInsights,
                webServiceUrls: Portico.Models.Interfaces.IWebServiceUrls
            ) => {
                function track(response: ResponseType) {
                    if (!response || !response.config || !response.config.aiData)
                        return;

                    const data = response.config.aiData;
                    angular.extend(data, <Partial<AiRecord>>{
                        responseFinishedTime: (window as any).Microsoft.ApplicationInsights.dateTime.Now(),
                        status: response.status,
                    });
                    data.CalculateMetrics();

                    var dependency = new (window as any).AI.RemoteDependencyData(
                        data.id,
                        data.getAbsoluteUrl(),
                        data.getPathName(),
                        data.ajaxTotalDuration,
                        +data.status >= 200 && +data.status < 400,
                        +data.status,
                        data.method
                    );

                    // enrich dependency target with correlation context from the server
                    var correlationContext = getCorrelationContext(response);
                    if (correlationContext) {
                        dependency.target = dependency.target + ' | ' + correlationContext;
                    }

                    if ((appInsights as any).ai.trackDependencyData)
                        (appInsights as any).ai.trackDependencyData(data);

                    response.config.aiData = null;
                }

                function getCorrelationContext(response: ResponseType) {
                    try {
                        var responseHeadersString =
                            response.headers[
                                (window as any).Microsoft.ApplicationInsights.RequestHeaders
                                    .requestContextHeader
                            ];
                        if (responseHeadersString !== null) {
                            var responseHeader =
                                response.headers[
                                    (window as any).Microsoft.ApplicationInsights.RequestHeaders
                                        .requestContextHeader
                                ];
                            return (window as any).Microsoft.ApplicationInsights.CorrelationIdHelper.getCorrelationContext(
                                responseHeader
                            );
                        }
                    } catch (e) {}
                }

                return <ng.IHttpInterceptor>{
                    request(config: RequestType) {
                        if (_.startsWith(config.url, webServiceUrls.gateway.url)) {
                            if (!(window as any).Microsoft ||
                                !(window as any).Microsoft.ApplicationInsights ||
                                !(window as any).Microsoft.ApplicationInsights.ajaxRecord)
                                return config;
                            const data = (config.aiData = new (window as any).Microsoft.ApplicationInsights.ajaxRecord(
                                (window as any).Microsoft.ApplicationInsights.Util.newId()
                            ));
                            angular.extend(data, <Partial<AiRecord>>{
                                method: config.method,
                                requestUrl: config.url,
                                requestSentTime: (window as any).Microsoft.ApplicationInsights.dateTime.Now(),
                            });
                            config.headers[
                                (window as any).Microsoft.ApplicationInsights.RequestHeaders.requestIdHeader
                            ] =
                                data.id;
                            if (appInsights.ai.context) {
                                const appId = (appInsights as any).ai.context.appId();
                                if (appId) {
                                    config.headers[
                                        (window as any).Microsoft.ApplicationInsights.RequestHeaders.requestContextHeader
                                    ] = `${
                                        (window as any).Microsoft.ApplicationInsights.RequestHeaders
                                            .requestContextAppIdFormat
                                    }${appId}`;
                                }
                            }
                        }
                        return config;
                    },
                    response(response: ResponseType) {
                        track(response);
                        return response;
                    },
                    responseError(response: ResponseType) {
                        track(response);
                        return response;
                    },
                };
            },
        ]);
        $httpProvider.interceptors.push('aiTrackGateway');
    }
}
